<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Stock</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Stock en Almacen</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-2 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>REPORTE GENERAL DE STOCK</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
            autoResize="height"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-7">
          <span><strong>NOMBRE PRODUCTO:</strong></span>
          <div class="p-inputgroup">
            <AutoComplete
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              @item-select="BuscarFiltro()"
              placeholder="Escriba Nombre Producto"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }}</strong
                  >
                  <span> ** </span>
                  <span
                    
                    :class="
                      stockClass(
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock[0].cantidad_unidad)
                          : 0
                      )
                    "
                    ><strong>{{
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad_unidad)
                        : 0
                    }}</strong></span
                  >
                  <span> ** </span>
                  <span
                    :class="
                      stockClass(
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock[0].cantidad)
                          : 0
                      )
                    "
                    ><strong>{{
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad)
                        : 0
                    }}</strong></span
                  >
                </div>
              </template>
            </AutoComplete>
            <Checkbox
              v-model="busqueda_caso_uso"
              :binary="true"
              class="mt-1 ml-2"
              v-tooltip.top="'Buscar por Principio Activo'"
            /><Badge class="bg-info text-white mt-1 ml-1">P.ACTIVO</Badge>
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>TIPO CLIENTE: </strong></span>
          <Dropdown
            v-model="tipoClienteSelected"
            :options="tipo_clientes"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3" v-if="false">
          <span><strong>LABORATORIO: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre"
            v-model="laboratorioSelected"
            :suggestions="laboratoriosListadoFiltrado"
            @complete="buscarLaboratorio($event)"
            placeholder="Buscar Laboratorio"
           
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.nombre }}
                <!-- **
                  {{ slotProps.item.nombre_proveedor }} -->
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-3" v-if="false">
          <span><strong>PROVEEDOR: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre_completo"
            v-model="proveedorSelected"
            :suggestions="proveedoresListadoFiltrado"
            @complete="buscarProveedor($event)"
            placeholder="Buscar Proveedor"
          />
        </div>
        <div class="field col-12 md:col-1">
          <span><strong>S.M&Iacute;N: </strong></span>
          <InputNumber
            id="precio_minimo"
            v-model="precio_minimo"
            :showButtons="true"
            :min="0"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>S.M&Aacute;X: </strong></span>
          <InputNumber
            id="precio_maximo"
            v-model="precio_maximo"
            :showButtons="true"
            :min="0"
          />
        </div>
        <div class="field col-12 md:col-1">
          <span><strong>STOCK 0: </strong></span><br />
          <Checkbox value="sin_stock" v-model="stock_cero" />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-info"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
        <div
          class="field col-12 md:col-12"
          v-if="'R_Stock Diferencia' in auth.user.permissions"
        >
          <Checkbox
            v-model="dif_cant_stock_fecha_vence"
            :binary="true"
            class="mt-1"
          />
          <span class="ml-1"
            ><strong
              >VER DIFERENCIA DE STOCK CON FECHA DE VENCIMIENTO</strong
            ></span
          >
        </div>
      </div>
    </div>
    <div
      class="card estilos-card mt-2"
      v-if="stocks.length > 0 && 'Producto Actualizar' in auth.user.permissions"
    >
      <div class="p-fluid formgrid grid align-items-center">
        <div class="field col-12 md:col-12 mt-2">
          <div
            class="p-inputgroup align-items-center"
            style="background-color: #f8d7da; padding: 2px; border-radius: 3px"
          >
            <i class="pi pi-info-circle p-mr-2" style="font-size: 1rem"></i>
            <span class="mr-1"><strong>NOTA: </strong></span>
            <small style="font-weight: bold; color: #ff0000">
              Si incrementa el precio en un % (por ejemplo, 10%) y luego desea
              reducirlo, se recomienda hacerlo con el mismo % (por ejemplo,
              -10%) para mantener la coherencia en los cálculos.
            </small>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup align-items-center">
            <Checkbox
              v-model="updatePrice"
              :binary="true"
              @change="porcentaje_update = updatePrice ? 0 : null"
              :disabled="stocks.length == 0"
            />
            <span class="ml-1"
              ><strong>ACTUALIZAR PRECIOS DE VENTA</strong></span
            >
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup align-items-center">
            <Checkbox
              v-model="redondear_precio"
              :binary="true"
              :disabled="!updatePrice"
            />
            <span class="ml-1"><strong>REDONDEAR PRECIO</strong></span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <InputNumber
            v-model="porcentaje_update"
            :showButtons="true"
            :min="-100"
            :max="100"
            :suffix="'%'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :disabled="!updatePrice"
            class="p-inputtext-sm"
          />
        </div>
        <div class="field col-12 md:col-3">
          <Button
            icon="pi pi-refresh"
            label="ACTUALIZAR PRECIOS"
            class="p-button-success p-button-sm"
            style="font-weight: bold"
            :loading="actualizando_precios"
            :disabled="
              actualizando_precios || !updatePrice || !porcentaje_update
            "
            @click="updatePrice ? updatePrecioVentaModal() : null"
          />
        </div>
      </div>
    </div>
    <div
      class="mt-2 card style_card"
      v-if="
        stocks.length > 0 && 'R_Ganancias de Ganancias' in auth.user.permissions
      "
    >
      <div class="p-fluid formgrid grid">
        <table class="table table-bordered table-striped" style="width: 100%">
          <tr>
            <th class="text-center">
              TOTAL INVERSI&Oacute;N BRUTA:
              <span class="p-badge p-badge-blue p-mr-2" style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(sumaTotalInversion ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              TOTAL VALOR VENTA:
              <span
                class="p-badge p-badge-danger p-mr-2"
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(sumaTotalValorVenta ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              TOTAL UTILIDAD:
              <span
                class="p-badge p-badge-success p-mr-2"
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(sumaTotalUtilidad ?? 0)
                }}
                Bs.</span
              >
            </th>
          </tr>
        </table>
      </div>
    </div>
    <div class="mt-1">
      <DataTable
        ref="reporte_stock"
        key="id"
        :value="stocks"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Stock"
        selectionMode="single click"
        v-model:expandedRows="expandedRows"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Stock..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="PDF Stock"
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger"
                v-tooltip.top="'Exportar PDF Stock en Almacen'"
                :loading="generando_pdf"
                :disabled="generando_pdf"
                @click="generarPDF()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="EXCEL Exportar"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success mr-2"
                v-tooltip.top="'Exportar Stock en Almacen'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStock()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Stock DescargarEXCEL' in auth.user.permissions"
                label="EXCEL Control de Stock"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Exportar Control de Stock'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStockControl()"
              />
            </span>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span
            class="flex align-items-center justify-content-center"
            style="
              background-color: #f8d7da;
              padding: 5px;
              border-radius: 5px;
              color: #721c24;
            "
          >
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column expander style="width: 1rem"></Column>
        <!-- <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column> -->
        <Column field="cod_producto" header="CÓD"></Column>
        <Column
          field="sucursal"
          header="SUCURSAL"
          :sortable="true"
          v-if="sucursales.length > 2"
        >
          {{ sucursal }}
        </Column>
        <!--  <Column field="laboratorio" header="LABORATORIO" :sortable="true" />
        <Column field="proveedor" header="PROVEEDOR" :sortable="true" />
      -->
        <Column
          field="producto"
          header="DESCRIPCIÓN/PRODUCTO"
          style="font-weight: bold"
        />
        <Column
          class="text-center"
          field="cantidad_unidad"
          header="STOCK UNIDAD"
          :sortable="true"
        >
          <template #body="{ data }">
            <div :class="claseEstado(data)">
              {{ sinDecimal(data.cantidad_unidad) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-center"
          field="cantidad"
          header="STOCK KG"
          :sortable="true"
        >
          <template #body="{ data }">
            <div :class="claseEstado(data)">
              {{ sinDecimal(data.cantidad) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="precio_compra"
          header="P.COMPRA"
          v-if="'Producto Actualizar' in auth.user.permissions"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_compra) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="precio_sugerido"
          header="CLIENTE FINAL"
          v-if="mostrar_precios_reporte_stock && this.tipoClienteSelected == 1"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="pv2"
          header="RESTAURANTES"
          v-if="mostrar_precios_reporte_stock && this.tipoClienteSelected == 2"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.pv2) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="pv3"
          header="CORPORATIVOS"
          v-if="mostrar_precios_reporte_stock && this.tipoClienteSelected == 3"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.pv3) }}
            </div>
          </template>
        </Column>
        
        <Column
          class="text-right"
          field="pv4"
          header="MAYORISTAS"
          v-if="mostrar_precios_reporte_stock && this.tipoClienteSelected == 4"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.pv4) }}
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="p-3" style="border: 1px solid #002236">
            <h5>
              <span style="border-bottom: 1px solid #ccc; text-align: center">
                FECHAS DE VENCIMIENTOS:
                <strong
                  >{{ slotProps.data.cod_producto }} -
                  {{ slotProps.data.producto }}</strong
                >
              </span>
            </h5>
            <DataTable
              :value="slotProps.data.fechas_vencimiento"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              stripedRows
              showGridlines
              selectionMode="single click"
            >
              <Column
                field="producto_id"
                header="COD_PROD"
                style="text-align: center"
              >
                <template #body="slotProps">
                  {{ slotProps.data.producto_id }}
                </template>
                ></Column
              >
              <Column
                field="descripcion"
                header="PRODUCTO/DESCRIPCIÓN"
                style="font-weight: bold; font-size: 1.2rem; text-align: center"
              ></Column>
              <Column
                header="CANTIDAD"
                style="font-weight: bold; font-size: 1.2rem; text-align: center"
              >
                <template #body="slotProps">
                  {{ slotProps.data.cantidad }}
                </template>
              </Column>
              <Column
                header="FECHA VENCIMIENTO"
                style="font-weight: bold; font-size: 1.2rem; text-align: center"
              >
                <template #body="slotProps">
                  {{
                    slotProps.data.fecha_vence == "1905-03-14"
                      ? "Sin Fecha"
                      : transformarFecha(slotProps.data.fecha_vence)
                  }}
                </template>
              </Column>
              <Column
                header="LOTE"
                style="font-weight: bold; font-size: 1.2rem; text-align: center"
              >
                <template #body="slotProps">
                  {{ slotProps.data.lote }}
                </template>
              </Column>
              <Column
                v-if="'R_Stock Diferencia' in auth.user.permissions"
                header="ACCIONES"
                style="font-weight: bold; font-size: 1.2rem; text-align: center"
              >
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-info p-button-sm"
                    @click="updateFechaVence(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
    <Dialog
      v-model:visible="modalActualizarPrecios"
      modal
      header="ACTUALIZAR PRECIOS DE VENTA"
      :style="{ width: '350px' }"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <div class="triangle-container">
            <i class="pi pi-exclamation-triangle"></i>
          </div>
          <div class="modal-message">
            <strong>¿ESTÁ SEGURO DE ACTUALIZAR LOS PRECIOS DE VENTA?</strong>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="p-d-flex p-jc-between">
          <Button
            label="CANCELAR"
            icon="pi pi-times"
            class="p-button-danger"
            @click="modalActualizarPrecios = false"
          />
          <Button
            label="ACTUALIZAR"
            icon="pi pi-save"
            class="p-button-info"
            @click="updatePrecioVenta()"
          />
        </div>
      </template>
    </Dialog>
  </div>
  <ActualizarFechaVence
    :show="actualizarFechaVenceDialog"
    :actualizarFechaVence="{ ...actualizarFechaVence }"
    @closeModal="cerrarModalActualizarFechaVence"
    @actualizarlistado="BuscarFiltro"
  />
</template>

<script>
import StockService from "@/service/StockService";
import SucursalService from "@/service/SucursalService";
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import { sum } from "lodash";
import ParametrosSistemaService from "@/service/ParametrosSistemaService";
import ActualizarFechaVence from "@/module/reportes/ActualizarFechaVence.vue";

export default {
  components: {
    ActualizarFechaVence,
  },
  data() {
    return {
      stocks: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      enviando: false,
      stock_cero: false,
      exportando: false,
      generando_pdf: false,
      productoSelected: null,
      productosListadoFiltrado: [],
      precio_minimo: 0,
      precio_maximo: 0,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      laboratorioSelected: null,
      laboratoriosListadoFiltrado: [],
      proveedorSelected: null,
      proveedoresListadoFiltrado: [],
      numero_elementos: 0,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      busqueda_caso_uso: false,
      parametros_sistema: {},
      mostrar_precios_reporte_stock: false,
      tipo_clientes: [],
      tipoClienteSelected: 1,
      expandedRows: [],
      updatePrice: false,
      porcentaje_update: null,
      redondear_precio: false,
      actualizando_precios: false,
      dif_cant_stock_fecha_vence: false,
      actualizarFechaVenceDialog: false,
      actualizarFechaVence: {},
      modalActualizarPrecios: false,
    };
  },
  stockService: null,
  sucursalService: null,
  productService: null,
  parametrosSistemaService: null,

  auth: null,
  created() {
    this.stockService = new StockService();
    this.sucursalService = new SucursalService();
    this.initBuscar();
    this.productService = new ProductService();
    this.auth = useAuth();
    this.parametrosSistemaService = new ParametrosSistemaService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarParametrosSistema();
  },
  computed: {
    sumaTotalInversion() {
      return sum(
        this.stocks.map((item) => {
          return item.cantidad * item.precio_compra;
        })
      );
    },
    sumaTotalValorVenta() {
      return sum(
        this.stocks.map((item) => {
          return item.precio_sugerido * item.cantidad;
        })
      );
    },
    sumaTotalUtilidad() {
      /* sumo el valor de this.sumaTotalValorVenta - this.sumaTotalInversion */
      return this.sumaTotalValorVenta - this.sumaTotalInversion;
    },
  },
  methods: {
    cerrarModalActualizarFechaVence() {
      this.actualizarFechaVenceDialog = false;
    },
    updateFechaVence(datos) {
      this.actualizarFechaVence = { ...datos };
      this.actualizarFechaVenceDialog = true;
    },
    updatePrecioVentaModal() {
      this.modalActualizarPrecios = true;
    },
    updatePrecioVenta() {
      this.actualizando_precios = true;
      let datos = {
        updatePrice: this.updatePrice ? true : false,
        redondear_precio: this.redondear_precio ? true : false,
        porcentaje: this.porcentaje_update,
        stocks: this.stocks,
      };
      this.stockService
        .updatePrecioVenta(datos)
        .then((data) => {
          if (data.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 6000,
            });
            this.actualizando_precios = false;
          } else {
            this.actualizando_precios = false;
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: data.message,
              life: 3000,
            });
            this.modalActualizarPrecios = false;
            this.BuscarFiltro();
          }
        })
        .catch(() => {
          this.actualizando_precios = false;
        });
    },
    soloPrimeraPalabra(texto) {
      if (texto) {
        return texto.split(" ")[0];
      } else {
        return "UNIDAD";
      }
    },
    transformarFecha(fecha) {
      //dd/mm/yyyy
      let fechaArray = fecha.split("-");
      return `${fechaArray[2]}/${fechaArray[1]}/${fechaArray[0]}`;
    },
    cargarParametrosSistema() {
      this.parametrosSistemaService
        .getParametrosSistema()
        .then((response) => {
          this.parametros_sistema = response.parametros_sistema ?? {};
          if (this.parametros_sistema) {
            this.parametros_sistema.forEach((element) => {
              if (element.nombre === "mostrar_precios_reporte_stock") {
                this.mostrar_precios_reporte_stock = true;
              }
            });
          }
          this.tipo_clientes = response.tipo_clientes ?? {};
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /* rowClass(data) {
      return {
        "p-highlight": data.cantidad <= 0,
      };
    }, */
    incrementar_numero_elementos() {
      this.numero_elementos++;
      return this.numero_elementos;
    },
    buscarLaboratorio(event) {
      setTimeout(() => {
        this.stockService
          .buscarLaboratorioXNombre({ texto: event.query })
          .then((response) => {
            this.laboratoriosListadoFiltrado = [...response.laboratorios];
          });
      }, 250);
    },
    buscarProveedor(event) {
      setTimeout(() => {
        this.stockService
          .buscarProveedorXNombre({ texto: event.query })
          .then((response) => {
            this.proveedoresListadoFiltrado = [...response.proveedores];
          });
      }, 250);
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
            busqueda_caso_uso: this.busqueda_caso_uso,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    generarPDF() {
      if (this.stocks.length > 0) {
        this.generando_pdf = true;
        let datos = {
          sucursal: this.sucursalSelected,
          stock_cero: this.stock_cero.length > 0 ? true : false,
          producto: this.productoSelected ? this.productoSelected.id : null,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService
          .imprimirReporte(datos)
          .then(() => {
            this.generando_pdf = false;
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: "Se generó el reporte correctamente",
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.generando_pdf = false;
          });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.stocks = [];
      this.enviando = true;
      this.cargarStocksSucursal();
    },
    cargarStocksSucursal() {
      let datos = {
        sucursal: this.sucursalSelected,
        producto: this.productoSelected,
        precio_minimo: this.precio_minimo,
        precio_maximo: this.precio_maximo,
        stock_cero: this.stock_cero.length > 0 ? true : false,
        proveedor_id: this.proveedorSelected ? this.proveedorSelected.id : null,
        fabrica_id: this.laboratorioSelected
          ? this.laboratorioSelected.id
          : null,
        dif_cant_stock_fecha_vence: this.dif_cant_stock_fecha_vence,
      };
      this.stockService
        .filtrarStockSucursal(datos)
        .then((data) => {
          this.stocks = data.stocks;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((element) => {
            this.sucursales.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportStockControl() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          producto: this.productoSelected,
          stock_cero: this.stock_cero,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService.exportStockAllControl(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    exportStock() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          producto: this.productoSelected,
          stock_cero: this.stock_cero,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService.exportStockAll(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    expandAll() {
      this.expandedRows = this.stocks.filter((p) => p.id);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
.estilos-card {
  border-radius: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0em;
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
}
.triangle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.pi-exclamation-triangle {
  color: #ffcc00;
  font-size: 2rem;
}

.modal-message {
  text-align: center;
}
</style>
